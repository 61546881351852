export const sitePages = [
  {
    text: "Page d'accueil",
    value: "/",
  },
  {
    text: "Page A propos",
    value: "/propos",
  },
  {
    text: "Page Services",
    value: "/services",
  },
  {
    text: "Page Services",
    value: "/services",
  },
  {
    text: "Page Contact",
    value: "/contact",
  },
  {
    text: "Page Assainissement",
    value: "/services/Assainissement",
  },
  {
    text: "Page Décontamination",
    value: "/services/Décontamination",
  },
  {
    text: "Page Hygiène",
    value: "/services/Hygiène",
  },
  {
    text: "Page Amiante",
    value: "/services/Amiante",
  },
  {
    text: "Page Enlèvement-déchets",
    value: "/services/Enlèvement-déchets",
  },
];
